import { type ReactElement, useCallback, useEffect, useState } from "react";

import Button from "../Button";
import { usePopup } from "../Popup/hook/usePopup";
import CitiesPopup from "../../popups/CitiesPopup";

import styles from "./styles.module.scss";
import { api } from "../../api";
import { type KnownApiError } from "../../api/types";
import { useCitiesPopup } from "./hook/useCitiesPopup";

export interface City {
  id: number;
  name: string;
}

export interface CityResponse {
  success: boolean;
  data: City[];
}

export default function CurrentCity(): ReactElement | null {
  const { showPopup, showInfoPopup, hidePopup } = usePopup();
  const { cityRequired, setCity, city } = useCitiesPopup();

  const [cities, setCities] = useState<City[]>([]);

  const fetchCities = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get<CityResponse>("cities");
      const { success, data } = response?.data ?? {};

      if (success) {
        setCities(data);
      }
    } catch (error) {
      const err = error as KnownApiError;
      showInfoPopup({ message: err.response?.data.error });
    }
  }, [showInfoPopup]);

  useEffect(() => {
    if (cityRequired) {
      void fetchCities();
    }
  }, [cityRequired, fetchCities]);

  const onSelectCity = useCallback(
    (city: City): void => {
      setCity(city);
      hidePopup();
    },
    [hidePopup, setCity]
  );

  const showCitiesPopup = useCallback(
    (closeButton = false): void => {
      showPopup({
        id: "CitiesPopup",
        content: <CitiesPopup cities={cities} successCallback={onSelectCity} />,
        closeButton,
      });
    },
    [cities, onSelectCity, showPopup]
  );

  useEffect(() => {
    if (cityRequired && cities.length > 0) {
      showCitiesPopup();
    }
  }, [cityRequired, cities, showCitiesPopup]);

  const handleButtonClick = useCallback(() => {
    showCitiesPopup(true);
  }, [showCitiesPopup]);

  if (!cityRequired) {
    return null;
  }

  return (
    <Button className={styles.CurrentCity} textClassName={styles.CurrentCity__text} onClick={handleButtonClick}>
      <b>{city?.name ?? "Выбрать город"}</b>
    </Button>
  );
}
