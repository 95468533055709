import { type ReactElement } from "react";
import Button from "../../components/Button";
import styles from "./styles.module.scss";
import { type City } from "../../components/CurrentCity";

interface CitiesPopupProps {
  successCallback: (city: City) => void;
  cities: City[];
}

export default function CitiesPopup({ successCallback, cities }: CitiesPopupProps): ReactElement {
  return (
    <div className={styles.CitiesPopup}>
      <b className={styles.CitiesPopup__title}>Укажите, в&nbsp;каком городе вы&nbsp;находитесь</b>
      <div className={styles.CitiesPopup__cities}>
        {cities.map((city) => (
          <Button
            key={`city-${city.id}`}
            className={styles.CitiesPopup__city}
            textClassName={styles.CitiesPopup__cityText}
            onClick={() => {
              successCallback(city);
            }}
            disabled={false}
          >
            {city.name}
          </Button>
        ))}
      </div>
    </div>
  );
}
